<template>
    <div class="modest-card">
         <div class="row justify-content-around">
            <div v-for="(card, index) in cards" :key="index" :class="['card', 'col-12', 'col-md-6', 'col-lg-4', index==0?'first':'', index==counter-1?'last':'']">
                <div class="card-wrapper align-center pb-1">
                    <div class="img-wrapper"><i :class="['iconfont', card.icon]"></i></div>
                    <div class="card-box align-center">
                        <h4 class="section-title pb-2 semibold display-6">{{ card.title }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default ({
        name: 'ModernCard',
        props: [ 'cards' ],
        data(){ return { position: '', counter:0 } },
        mounted(){
            this.counter = this.cards.length
        }
    })
</script>
<style scoped>

</style>