<template>

    <div class="card col-12 col-md-6 col-lg-4 d-flex">
        <div :class="['card-wrapper', 'card'+data.no]">
            <div class="card-box">      
                <h4 class="card-title text-lighter align-center bold pb-4 display-5">{{data.title}}</h4>
                <p class="section-text align-center pt-3 regular display-7">{{data.subtitle}}</p>
                <div v-for="(textitem, index) in data.texts" :key="index">
                    <p class="align-left regular display-7 m-0"><i class="fas fa-check me-2"></i>{{textitem}}</p>
                </div>
            
                <div class="d-flex flex-row justify-content-around">
                    <span v-for="(button, index) in data.buttons" :key="index">
                        <a :href="'/storage/'+button.link">                 
                            <button type="button" class="btn btn-outline-modest mt-5">{{button.descr}}</button>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
    export default {
        name: 'modernGallery',
        props: [ 'data' ],
    }

</script>
<style scoped>
.modest-gallery .card {
    min-height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-box-reflect: below 0 -webkit-gradient(
        linear, 
        left top, 
        left bottom, 
        from(transparent), 
        color-stop(70%, transparent) , 
        to(rgba(250, 250, 250, 0.1))
        );
}
.modest-gallery .card1 { background: url("../assets/resized/soma.png");   }
.modest-gallery .card2 { background: url("../assets/resized/pycode.png"); }
.modest-gallery .card3 { background: url("../assets/resized/tkcode.png"); }
.modest-gallery .card4 { background: url("../assets/resized/ydl1.png");   }
.modest-gallery .card5 { background: url("../assets/resized/resize.png"); }


@media (max-width: 3840px){
    .data-layout {
        display: flex;
        flex-direction: row;
        justify-content: around;
    }
}

@media(max-width: 1023px){
    .data-layout {
        display: flex;
        flex-direction: column;
    }
}

</style>