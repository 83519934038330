<template>

    <Navbar :pagetitle='pagetitle' :navitems="navitems"/>
    <Header :texts="header"/>
    <Card   :cards="cards"/>
    <div class="modest-gallery bg-med row d-flex justify-content-between" id="oldApps">
        <Gallery :data="soma" />
        <Gallery :data="pycode" />
        <Gallery :data="tkcode" />
    </div>
    <Footer />

</template>
<script>

import Navbar from '@/components/Navbar'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Card   from '@/components/ModernCard'
import Gallery from '@/components/ModernGallery'

export default({
    name: 'Other',
    components: { Navbar, Header, Card, Gallery, Footer },
    data(){ return {
        pagetitle:'desktop|re-training',
        navitems: [ {
                title: '20|21',
                route: 'other',
            }, {
                title: 'preDegree',
                route: 'older',
            }
        ],
        cards: [ 
            {icon: 'fas fa-money-bill-wave', title: 'free'},
            {icon: 'far fa-file-code', title: 'modern'},
            {icon: 'fas fa-cogs', title: 'useful'},
            ],
        header: ['Applications','for Windows','& MacOS'],

        soma: {
                no: 1,
                title: 'somaFM',
                subtitle: 'I ❤️ SomaFM!',
                texts: ['This little app i had to build to listen to my favourite station during retraining in office.','Very simple, but working.'],
                buttons: [ {link: 'somaFM.rar', descr: 'get portable'} ]
            },
        pycode: {
                no: 2,
                title:'pyCode',
                subtitle:'First Qt5',
                texts: ['Get webpage HTML and links.','Work on code.','Preview in app.',' Upload to your server or save locally.','Makeover is in progress'],
                buttons: [{link: 'pyCode.rar', descr:'get portable'}]
            },
        tkcode: {
                no: 3,
                title:'tkCode',
                subtitle:'As you may have guessed..',
                texts: ['pyCodes precursor, a tKinter version.', 'Same functionality with external browser.'],
                buttons: [{link: 'tkCode.rar', descr:'get portable'}]
            }
        
        }
    },
})
</script>
<style scoped></style>